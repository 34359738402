import "./PricingView.css";

import Header from "../../components/header/Header";

const LifeCoachBg = require("../../asset/icon/1/bg.svg").ReactComponent;
const ConsultationBg = require("../../asset/icon/2/bg.svg").ReactComponent;
const RelaxBg = require("../../asset/icon/3/bg.svg").ReactComponent;
const WomenCircleBg = require("../../asset/icon/4/bg.svg").ReactComponent;


export default function PricingView() {
    return <div className={"pricing-view"}>
        <Header/>
        <h2>Ceník</h2>
        <div className={"pricing-container"}>
            <div className={"pricing-entry"}>
                <LifeCoachBg/>
                <div>
                    <h2>Životní kouč</h2>
                    <p>1 000 Kč / hodina</p>
                </div>
            </div>
            <div className={"pricing-entry"}>
                <ConsultationBg/>
                <div>
                    <h2>Konzultace</h2>
                    <p>1 000 Kč / hodina</p>
                </div>
            </div>
            <div className={"pricing-entry"}>
                <RelaxBg/>
                <div>
                    <h2>Relax</h2>
                    <p>1 000 Kč / hodina</p>
                </div>
            </div>
            <div className={"pricing-entry"}>
                <WomenCircleBg/>
                <div>
                    <h2>Ženské kruhy</h2>
                    <p>Cena dle aktuální nabídky</p>
                </div>
            </div>
        </div>
    </div>
}