import "./Header.css"
import {Link} from "react-router-dom";

const Logo = require("../../asset/logo/logo.svg").ReactComponent;

export default function Header() {
    return <header>
        <Link className={"logo"} to={"/"}>
            <Logo aria-label={"Domů"}/>
            <h1 className={"name"}>jushi</h1>
            <h2 className={"name"}>Jaroslava Balcarová</h2>
        </Link>

        <div className={"nav"}>
            <Link to="/pribeh">O Mně</Link>
            <Link to="/cenik">Ceník</Link>
            <Link to="/certifikaty">Certifikáty</Link>
            <Link to="/gdpr">GDPR</Link>
            <Link className={"space"} to="/kontakt">Kontakt</Link>
        </div>
    </header>
}